.sectiondrop {
  display: grid;
}

.sectionlogin {
  width: 30vw;
  display: grid;
  align-items: center;
  justify-content: center;
}

.dropzone {
  height: 7rem;
  width: 35rem;
  background: var(--cor-dropzone-fundo);
  position: relative;
  border-radius: 5px;
  color: var(--cor-dropzone-letra);
  text-align: center;
  border-color: var(--cor-dropzone-borda);
  min-height: auto;
  margin-top: 20px;
}

.drop_disabled {
  background: #e9e9e9;
  color: #b6b6b6;
}

.textdrop {
  font-weight: 500;
  font-size: 1.5rem;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}

.archivodrop {
  font-size: 1.2rem;
  color: #3d36a7;
  padding: 0.5rem;
  left: 50%;
  position: absolute;
  top: 80%;
  transform: translate(-50%, -50%);
}

.buttondrop {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 20px;
  color: #eeeeee;
  background-color: #569B51;
  border-radius: 5px;
  border: none;
  font-family: 'Space Mono', monospace;
}

.buttondrop:hover {
  background-color: #396736;
}

.accept {
  background-color: #000000 !important;
}

.reject {
  border-color: #d83b01 !important;
}

.titlelogin {
  font-size: 2em;
  padding-bottom: 0.5em;
}

.plogin {
  font-size: 0.8em;
  padding-bottom: 3em;
  width: 20rem;
}

.barra {
  height: 2px;
  background-color: #39424E;
  width: 20rem;
  margin-bottom: 3em;
}

.logo {
  vertical-align: middle;
  padding-right: 2em;
}

.buttongoogle {
  font-family: monospace;
  color: #eeeeee;
  border: #eeeeee solid 1px;
  padding: 10px;
  border-radius: 5px;
  width: 20rem;
  margin-bottom: 3em;
}

.buttongoogle:hover {
  background-color: #232730;
}